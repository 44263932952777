import { Component, EventEmitter, OnInit } from '@angular/core';
import { DialsService } from '../../prospect/components/prospect-tab/services/dials.service';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { FilterService, PageChangeEvent } from '@progress/kendo-angular-grid';
import { UserManagementService } from '../../user-management/services/user-management.service';

@Component({
  selector: 'app-dial-count',
  templateUrl: './dial-count.component.html',
  styleUrls: ['./dial-count.component.css']
})
export class DialCountComponent implements OnInit {

  totalDialCount: any = [];
  masterDataList: any = [];
  userId: any;
  currentDate: Date = new Date();
  todayDate: Date = new Date();
  userDetailsData: any;
  disabledNextButton: boolean = true;
  gridFilterArrayRoleName: any
  disabled = true;
  public gridFilter: CompositeFilterDescriptor = {
    logic: "and",
    filters: [],
  };
  public pageSize = 10;
  public buttonCount = 5;
  public sizes = [10, 25, 50, 100, 250, 500];
  public skip = 0;
  MasterFilterData: any;
  filterdataChangeFlag: boolean = false;
  rolesList: any
  filterValueCopy: any
  constructor(
    public _dialService: DialsService,
    private _userManagementService: UserManagementService,
    public datepipe: DatePipe,
    private titleService: Title
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Dial Count')
    this.userDetailsData = JSON.parse(localStorage.getItem('userDetails')!)
    this.getUserRole();
    if (this.userDetailsData) {
      this.userId = this.userDetailsData?.user?.userId
      this.userInformation(this.userId, this.datepipe.transform(this.currentDate, 'yyyy-MM-dd'))
    }
  }

  getNoOfDialCount(userId: any, date: any) {
    // if (userId && date) {
    if (date) {
      let request = {
        currentDate: date
      }
      if (request) {
        this._dialService.getDialCountList(request).subscribe((resp: any) => {
          // this.totalDialCount = resp?.dialCountList?.dialsCount;
          this.totalDialCount = {
            data: resp?.dialCountList?.dialsCount,
            total: resp?.dialCountList?.dialsCount.length,
          };
          this.masterDataList = resp?.dialCountList?.dialsCount;
        })
      }
    }
  }

  userInformation(userId: any, currentDate: any) {
    // if (userId && currentDate) {
    //   this.getNoOfDialCount(userId, currentDate)
    // }
    this.getNoOfDialCount("", currentDate)
  }

  dailCountDateChange(val: any, callType: any) {
    this.disabled = false;
    let today = new Date(val);
    let currentDate1 = callType == 'Next' ? today.setDate(today.getDate() + 1) : today.setDate(today.getDate() - 1);
    this.currentDate = new Date(currentDate1)
    let cuDate = this.datepipe.transform(this.currentDate, 'yyyy-MM-dd')
    let todayDate = this.datepipe.transform(this.todayDate, 'yyyy-MM-dd')
    let actualDate = this.datepipe.transform(this.currentDate, 'yyyy-MM-dd')
    if (actualDate) {
      // this.getNoOfDialCount(this.userId, actualDate)
      this.getNoOfDialCount("", actualDate)
    }
    if (cuDate == todayDate) {
      this.disabled = true
      return
    }
  }



  changeDate(changeDate: any) {
    if (changeDate) {
      this.disabled = false
      let actualDate = this.datepipe.transform(changeDate, 'yyyy-MM-dd')
      if (actualDate) {
        // this.getNoOfDialCount(this.userId, actualDate)
        this.getNoOfDialCount("", actualDate)
      }
      let cuDate = this.datepipe.transform(this.currentDate, 'yyyy-MM-dd')
      let todayDate = this.datepipe.transform(this.todayDate, 'yyyy-MM-dd')
      if (cuDate == todayDate) {
        this.disabled = true
        return
      }
    }
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.gridFilter = { ...filter };
    if (this.gridFilter?.filters?.length > 0) this.filterdataChangeFlag = true;
    else this.filterdataChangeFlag = false;
    this.filterValueCopy = JSON.parse(JSON.stringify(this.gridFilter));
    this.filterValueCopy = this.transformFilters(this.filterValueCopy)
    this.filterDailCount()
  }
  filterDailCount() {
    this.MasterFilterData = filterBy(this.masterDataList, this.filterValueCopy);
    this.totalDialCount = {
      data: this.MasterFilterData,
      total: this.MasterFilterData.length,
    };
  }
  dailPaginate(event: PageChangeEvent) {
    this.skip = event.skip
    this.pageSize = event.take
    if (this.filterdataChangeFlag) {
      this.totalDialCount = {
        data: this.MasterFilterData.slice(this.skip, this.skip + this.pageSize),
        total: this.MasterFilterData.length,
      }
    }
    else {
      this.totalDialCount = {
        data: this.masterDataList.slice(this.skip, this.skip + this.pageSize),
        total: this.masterDataList.length,
      };
    }
  }

  //get user role method
  getUserRole() {
    this.rolesList = [];
    this._userManagementService.getUserRoleList().subscribe(
      (resp: any) => {
        if (resp) {
          this.rolesList = resp.rolesList;
        }
      });
  }

  //covert array to string
  transformFilters(input: any) {
    // Iterate through each filter object in the input array
    const transformedArray = [];
    for (const item of input?.filters) {
      const splitValues = item.filters.reduce((acc: any, filter: any) => {
        if (filter?.field == "roleName") {
          if (filter?.value?.includes(',')) {
            const values = filter?.value?.split(',').map((val: any) => val.trim());
            values?.forEach((value: any) => {
              acc.push({
                field: filter.field,
                operator: filter.operator,
                value: value
              });
            });
          }
          else {
            acc.push(filter);
          }
        }
        else {
          acc.push(filter);
        }
        return acc;
      }, []);

      const transformedItem = {
        filters: splitValues,
        logic: "or" // Setting logic to "or"
      };

      transformedArray.push(transformedItem);
    }
    return transformedArray;

  }


  public onRoleNameChange(values: string[], filterService: FilterService): void {
    filterService.filter({
      filters: values.map((value) => ({
        field: "roleName",
        operator: "eq",
        value,
      })),
      logic: "or",
    });
  }
}

import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AddCarrierComponent } from '../add-carrier/add-carrier.component';
import { NavigationPath } from 'src/app/shared/class/navigation-path';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { UserMessages } from 'src/app/shared/class/user-messages';
import { EditCarrierComponent } from '../edit-carrier/edit-carrier.component';
import { CarriersService } from '../services/carriers-service';
import { CarriersPaginatorModel } from '../shared/carriers-paginator';
import { Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { CompositeFilterDescriptor, filterBy, SortDescriptor } from '@progress/kendo-data-query';
import { FilterExpression } from '@progress/kendo-angular-filter';
import { DataBindingDirective, PageChangeEvent } from '@progress/kendo-angular-grid';
import { PreventBackButtonService } from 'src/app/shared/services/prevent-back-button.service';
import { ProfileInfoService } from 'src/app/shared/services/profile-info.service';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-carrier-list',
  templateUrl: './carrier-list.component.html',
  styleUrls: ['./carrier-list.component.css'],
})
export class CarrierListComponent implements OnInit, OnDestroy {
  idList: any;
  deleteFrom: any;
  highlightedRow: number = 0;
  _navigationPath: NavigationPath;
  _confirmBox: ConfirmBox;
  deletedCarrierIds: any[] = [];
  _userMessages: UserMessages;
  checkedAll: boolean = false;
  checkedOne: boolean = false;
  checkedCarrierIds: any[] = [];
  carrierList: any;
  carrierList$!: Observable<any>;
  deleteCarriers$!: Observable<any>;
  selectedCarriersCount: any;
  _paginator: CarriersPaginatorModel;
  deleteCheckedCalled: boolean = false;
  errorMessage: any;
  errorList: any[] = [];
  showError: boolean = false;
  unsubscribe$: Subject<boolean> = new Subject();
  $searchingText = new Subject<void>();
  public multiple = false;
  FilterBlankcheck: boolean = false;
  // Telrik start
  selectedParPageOption: number = 0;
  public filterValue: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  filteredDataCarrierIds: any;
  masterCarrierList: any[] = [];
  selectedProspectCount = 0;
  checkedListOrCarrierIds: any = { idList: [] };
  public gridData: unknown[] = this.masterCarrierList;
  selectedListId: number = 0;
  public pageSize = 10;
  public buttonCount = 5;
  public sizes = [10, 25, 50, 100, 250, 500];
  public skip = 0;
  isFilter: boolean = true;
  pageNo: number = 1;
  public totalCount = 0;
  searchGrid: string = '';
  @ViewChild(DataBindingDirective) dataBinding!: DataBindingDirective;
  FinalCarrierList: any = [];
  public gridFilter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [],
  };
  public sort: SortDescriptor[] = [
    {
      field: 'carrierShortName',
      dir: 'asc',
    },
  ];
  active: any = [];
  Inactive: any = [];
  pkgTierList: any[] = [];
  pkgTierList$!: Observable<any>;
  wcTierList: any[] = [];
  wcTierList$!: Observable<any>;
  gridFilterWCTier: any;
  gridFiltermasterPackageTier: any;

  //searching in column filter
  public filterChange(filter: CompositeFilterDescriptor): void {
    this.gridFilter = { ...filter };
    if (this.gridFilter.filters.length > 0) {
      var FilterWCTierIndex = this.gridFilter.filters.findIndex((x: any) => x.field == "masterWorkersCompensationTier")
      if (FilterWCTierIndex > -1 && this.gridFilterWCTier.length == 0)
        this.gridFilter.filters.splice(FilterWCTierIndex, 1);
      if (FilterWCTierIndex < 0) this.gridFilterWCTier = [];
      let masterPackageTierIndex = this.gridFilter.filters.findIndex((x: any) => x.field == "masterPackageTier")
      if (masterPackageTierIndex > -1 && this.gridFiltermasterPackageTier.length == 0)
        this.gridFilter.filters.splice(masterPackageTierIndex, 1);
      if (masterPackageTierIndex < 0) this.gridFiltermasterPackageTier = []

      let masterCarrierTypeIndex = this.gridFilter.filters.findIndex((x: any) => x.field == "masterCarrierType")
      if (masterCarrierTypeIndex > -1 && this.gridmasterCarrierTypeList.length == 0)
        this.gridFilter.filters.splice(masterCarrierTypeIndex, 1);
      if (masterCarrierTypeIndex < 0) this.gridmasterCarrierTypeList = []
    }
    if (!this.gridFilter?.filters.length) {
      this.gridFiltermasterPackageTier = [];
      this.gridFilterWCTier = [];
      this.gridmasterCarrierTypeList = []
    }
    this.skip = 0;
    this.pageNo = 1;
    this.totalCount = 0;
    this.$searchingText.next();
  }

  public filters: FilterExpression[] = [];
  isInActive: boolean = false;
  isActive: boolean = false;
  checkedListOrCarrierActiveIds: any = { idList: [] };
  isAllChecked: boolean = false;
  selectedValue: any = [];
  selectedItem: any = [];
  selectedItemsArr: any = []
  msgValue: any;
  unAthorizesd: any = '';
  masterCarrierTypeList: any;
  gridmasterCarrierTypeList: any
  // Telrik end
  constructor(
    private modalService: NgbModal,
    private _carriersService: CarriersService,
    private _preventBack: PreventBackButtonService,
    public profileInfoService: ProfileInfoService,
    private titleService: Title,
  ) {
    this._navigationPath = new NavigationPath();
    this._paginator = new CarriersPaginatorModel();
    this._confirmBox = new ConfirmBox();
    this._userMessages = new UserMessages();
    this.$searchingText.pipe(
      debounceTime(1000)
    ).subscribe(() => {
      this.getCarriersListInfo();
    });
  }
  ngOnInit(): void {
    this.titleService.setTitle('Carrier/Wholesaler Management')
    this.profileInfoService.statusCode.subscribe((res: any) => {
      this.unAthorizesd = res ? res : '';
    })
    this._preventBack.preventBackButton();
    this.masterCarrierTypeList = ['Carrier', 'Wholesaler']
    this.getPKGTierList();
    this.getWCTierList()
    this.getCarriersListInfo();
  }
  closeError() {
    this.showError = false;
    this.unAthorizesd = '';
  }
  // Telrik start
  public applyFilter(value: CompositeFilterDescriptor): void {
    this.isAllChecked = false;
    this.checkedListOrCarrierIds.idList = [];
    this.selectedItemsArr = [];
    this.FinalCarrierList.data = filterBy(this.masterCarrierList, value);
    this.filterValue = value;
    let id: any = [];
    this.filteredDataCarrierIds = {
      idList: [],
    };
    this.FinalCarrierList.data.forEach((x: any) => {
      this.filteredDataCarrierIds.idList.push(x.id);
    });
  }

  changeSelection(item: any) {
    this.deleteFrom = '';
    item.isSelected = !item.isSelected;
    this.selectedItem.push(item);
    if (item.isSelected == true) {
      if (item.carrierStatus == 'Active') {
        this.selectedItemsArr.push(item.carrierStatus)
        this.checkedListOrCarrierIds.idList.push(item.id);
      } else {
        this.selectedItemsArr.push(item.carrierStatus)
        this.checkedListOrCarrierIds.idList.push(item.id);
      }
    } else {
      for (var i = 0; i < this.carrierList?.length; i++) {
        if (this.checkedListOrCarrierIds.idList[i] == item.id) {
          this.checkedListOrCarrierIds.idList.splice(i, 1);
          this.selectedItemsArr.splice(i, 1);
        }
      }
    }
    // this.isAllChecked = new Set(this.status).size == 1;

    // checking if all items are 'Active'
    const allActivate = this.selectedItemsArr.every((a: any) => a === "Active");

    // checking if all items are 'Inactive'
    const allDeactivate = this.selectedItemsArr.every((b: any) => b === "Inactive");

    // Set isActive based on whether all items are 'Active' or 'Inactive'
    if (allActivate) {
      this.isInActive = true;
      this.msgValue = 'deactivate';
      this.isAllChecked = true;
    } else if (allDeactivate) {
      this.isInActive = false;
      this.msgValue = 'activate';
      this.isAllChecked = true;
    } else {
      this.isAllChecked = false; // Indicates mixed items or invalid data
    }

    if (this.selectedItemsArr.length == 0) this.isAllChecked = false;
  }

  //searching on all columns
  public onFilter(): void {
    if (this.searchGrid === "" && !this.FilterBlankcheck) {
      return
    }
    else if (this.searchGrid === "" && this.FilterBlankcheck) {
      this.FilterBlankcheck = false
      this.isAllChecked = false;
      this.checkedListOrCarrierIds.idList = [];
      this.selectedItemsArr = [];
      this.pageNo = 1;
      this.skip = 0;
      this.totalCount = 0;
      this.getCarriersListInfo();
    }
    else {
      this.FilterBlankcheck = true;
      this.isAllChecked = false;
      this.checkedListOrCarrierIds.idList = [];
      this.selectedItemsArr = [];
      this.pageNo = 1;
      this.skip = 0;
      this.totalCount = 0;
      this.getCarriersListInfo();
    }
  }
  @HostListener('document:keypress', ['$event'])
  startSearch(event: KeyboardEvent) {
    if (event.code === "Enter") {
      this.onFilter()
    }
  }

  searchValue(inputValue: any) {
    this.searchGrid = inputValue;
  }


  carrierPaginate(event: PageChangeEvent) {
    this.isAllChecked = false;
    this.checkedListOrCarrierIds.idList = [];
    this.selectedItemsArr = [];
    this.skip = event.skip;
    this.pageSize = event.take;
    this.pageNo = (event.take + event.skip) / event.take;
    this.totalCount = this.FinalCarrierList.total
    //calling api
    this.getCarriersListInfo();
  }

  //sorting based on column arrow
  public sortChange(sort: SortDescriptor[]): void {
    this.skip = 0;
    this.pageNo = 1;
    this.isAllChecked = false;
    this.checkedListOrCarrierIds.idList = [];
    this.selectedItemsArr = [];
    this.totalCount = this.FinalCarrierList.total
    this.sort = sort;
    this.getCarriersListInfo();
  }

  // Telrik end
  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  getCarriersListInfo() {
    let filterValueCopy = JSON.parse(JSON.stringify(this.gridFilter));
    filterValueCopy?.filters?.forEach((obj: any) => {
      obj?.filters?.forEach((x: any) => {
        if (x.field == 'carrierStatus') {
          if (x.value == true) {
            x.value = 'Active'
          }
          if (x.value == false) {
            x.value = 'Inactive'
          }
        }
      })
    })
    let data = {
      "pageNumber": this.pageNo,
      "pageSize": this.pageSize,
      "totalCount": this.totalCount,
      "sortDir": this.sort[0].dir,
      "sortCol": this.sort[0].field,
      "searchName": this.searchGrid,
      "userId": "",
      "gridFilterJson": filterValueCopy?.filters?.length > 0 ? JSON.stringify(filterValueCopy) : ""
    };
    this.carrierList$ = this._carriersService.getCarriersList(data);
    this.carrierList$.pipe(takeUntil(this.unsubscribe$)).subscribe(
      (resp: any) => {
        if (resp) {
          this.carrierList = resp.items[0].carrierList;
          this.masterCarrierList = this.carrierList;
          this._paginator.totalRecords = resp.totalRecord;
          this.FinalCarrierList = {
            data: this.masterCarrierList,
            total: resp.totalRecord,
          };
        }
      },
      ((err: any) => {
        if (err.status !== 200) {
          this.showError = true;
          if (err.status === 400) {
            let obj = err.error.errors.ListFilter;
            var arr = Object.keys(obj).map((key) => ({
              type: key,
              value: obj[key],
            }));
            this.errorMessage = arr[0].value;
            this.errorList.push(this.errorMessage);
          } else {
            this.errorMessage = "Error : " + err.message + ", Status: " + err.status;
            this.errorList.push(this.errorMessage);
          }
        }
      }
      )
    )
  }

  checkCarrierValue(carrier: any) {
    this.deleteFrom = '';
    this.checkedAll = false;
    if (this.checkedCarrierIds.includes(carrier.id)) {
      let index = this.checkedCarrierIds.indexOf(carrier.id);
      this.checkedCarrierIds.splice(index, 1);
    } else {
      this.checkedCarrierIds.push(carrier.id);
      if (this.checkedCarrierIds.length === this.carrierList.length) {
        this.checkedAll = true;
      }
    }
  }

  deleteCheckedCarriers(data: any) {
    this.deleteFrom = '';
    var statusValue = this.msgValue ? this.msgValue : 'activate'
    this._confirmBox.message = "Are you sure you wish to " + statusValue + '?';
    if (data) {
      this.selectedValue = [];
      this.selectedValue.push(data.id);
      this.deleteCheckedCalled = true;
      this._confirmBox.isVisible = true;
    } else {
      this.deleteCheckedCalled = true;
      this._confirmBox.isVisible = true;
    }
  }

  checkAll() {
    this.checkedAll = !this.checkedAll;
    this.checkedOne = !this.checkedOne;
    if (this.carrierList && this.checkedAll) {
      this.carrierList.forEach((element: any) => {
        this.checkedCarrierIds.push(element.id);
      });
    } else {
      this.checkedCarrierIds = [];
    }
  }

  //To open 'Add Carrier' Modal
  openAddCarrierModal() {
    this.highlightedRow = 0;
    const addmodalRef = this.modalService.open(AddCarrierComponent, {
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'customeDialog',
    });
    addmodalRef.closed.subscribe((resp: any) => { });
    addmodalRef.componentInstance.messageEvent.subscribe((data: any) => {
      if (data) {
        this.getCarriersListInfo();
      }
    });
  }

  openEditCarrierModal(carrier: any) {
    const editmodalRef = this.modalService.open(EditCarrierComponent, {
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'customeDialog',
    });
    editmodalRef.closed.subscribe((resp: any) => { });
    editmodalRef.componentInstance.carrierData = carrier;
    editmodalRef.componentInstance.messageEvent.subscribe((data: any) => {
      if (data) {
        this.getCarriersListInfo();
      }
    });
  }

  getCarriersList(result: any) {
    if (result.value !== '') {
      var data = {
        "pageNumber": this.pageNo,
        "pageSize": this.pageSize,
        "totalCount": this.totalCount,
        "sortDir": this.sort[0].dir,
        "sortCol": this.sort[0].field,
        "searchName": this.searchGrid,
        "userId": "",
        "gridFilterJson": this.gridFilter.filters.length > 0 ? JSON.stringify(this.gridFilter) : ""
      }
      this.carrierList$ = this._carriersService.getCarriersList(data);
      this.carrierList$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((resp: any) => {
          if (resp) {
            this.carrierList = resp.items[0].carrierList;
            this.masterCarrierList = this.carrierList;
            this._paginator.totalRecords = resp.totalRecord;
            this.FinalCarrierList = {
              data: this.masterCarrierList,
              total: resp.totalRecord,
            };
          }
        });
    } else {
      let data = {
        "pageNumber": this.pageNo,
        "pageSize": this.pageSize,
        "totalCount": this.totalCount,
        "sortDir": this.sort[0].dir,
        "sortCol": this.sort[0].field,
        "searchName": this.searchGrid,
        "userId": "",
        "gridFilterJson": this.gridFilter.filters.length > 0 ? JSON.stringify(this.gridFilter) : ""
      };
      this.carrierList$ = this._carriersService.getCarriersList(data);
      this.carrierList$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((response: any) => {
          if (response) {
            this.carrierList = response.items[0].carrierList;
            this.masterCarrierList = this.carrierList;
            this._paginator.totalRecords = response.totalRecord;
            this.FinalCarrierList = {
              data: this.masterCarrierList,
              total: response.totalRecord,
            };
          }
        });
    }
  }

  deleteCarrierList(event: any) {
    this.deleteFrom = 'fromList';
    let idArray: any = [];
    let isDeletedStatus: any;
    if (event.carrierStatus == 'Inactive') {
      this._confirmBox.message = this._userMessages.activate;
      isDeletedStatus = false;
    } else {
      this._confirmBox.message = this._userMessages.deactivate;
      isDeletedStatus = true;
    }
    this._confirmBox.isVisible = true;
    idArray.push(event.id)
    this.idList = {
      id: idArray,
      isDeleted: isDeletedStatus
    }
  }

  deleteCarrierData() {
    let data: any;
    if (this.deleteFrom == 'fromList') {
      data = {
        id: this.idList.id,
        isDeleted: this.idList.isDeleted
      }
    } else {
      data = {
        id: this.checkedListOrCarrierIds.idList,
        isDeleted: this.isInActive,
      };
    }
    this._carriersService.deleteCarriers(data).subscribe((res: any) => {
      if (res) {
        this.deleteFrom = ''
        this.isAllChecked = false;
        this.confirmBoxCancel();
        this.getCarriersListInfo();
        this.deletedCarrierIds = [];
        this.checkedCarrierIds = [];
        this.checkedListOrCarrierIds.idList = [];
        this.selectedItemsArr = [];
      }
    },
      ((error: any) => {
        if (error.status == 403) {
          this._confirmBox.isVisible = true;
        }
      })
    )
  }

  confirmBoxCancel() {
    this._confirmBox.isVisible = false;
  }

  closeModal() {
    this._confirmBox.message = this._userMessages.deleteTagMessage;
    this._confirmBox.isVisible = true;
  }
  changeSelections(item: any) {
    item.isSelected = !item.isSelected;
    if (item.isSelected == true) {
      this.selectedCarriersCount += 1;
    }
    if (item.isSelected == false) {
      this.selectedCarriersCount -= 1;
    }
  }

  //To hide column filters
  columnFilter(value: boolean) {
    this.isFilter = !value;
  }

  getPKGTierList() {
    this.pkgTierList$ = this._carriersService.getPKGTierList();
    this.pkgTierList$.pipe(takeUntil(this.unsubscribe$)).subscribe(
      (resp: any) => {
        if (resp) {
          this.pkgTierList = resp.packageTierList;
        }
      },
      (err: any) => {
        this.showError = true;
        this.errorMessage = err.message;
        this.errorList.push(this.errorMessage);
      }
    );
  }

  getWCTierList() {
    this.wcTierList$ = this._carriersService.getWCTierList();
    this.wcTierList$.pipe(takeUntil(this.unsubscribe$)).subscribe(
      (resp: any) => {
        if (resp) {
          this.wcTierList = resp.workersCompensationTierList;
        }
      },
      (err: any) => {
        this.showError = true;
        this.errorMessage = err.message;
        this.errorList.push(this.errorMessage);
      }
    );
  }

  public onmasterPackageTierChange(event: any): void {
    if (this.gridFilter.filters == undefined) this.gridFilter = { logic: 'and', filters: [] };
    if (this.gridFilter.filters.length > 0) {
      var stateIndex = this.gridFilter.filters.findIndex((x: any) => x.field == "masterPackageTier")
      if (stateIndex > -1)
        this.gridFilter.filters.splice(stateIndex, 1)
      let stateFilter = {
        field: "masterPackageTier",
        operator: "contains",
        value: event.toString()
      }
      if (event.length > 0) this.gridFilter.filters.push(stateFilter);
    } else {
      let stateFilter = {
        field: "masterPackageTier",
        operator: "contains",
        value: event.toString()
      }
      this.gridFilter.filters.push(stateFilter)
    }
    this.pageNo = 1;
    this.skip = 0;
    this.totalCount = 0;
    this.getCarriersListInfo();
  }
  public onmasterWorkersCompensationTierChange(event: any): void {
    if (this.gridFilter.filters == undefined) this.gridFilter = { logic: 'and', filters: [] };
    if (this.gridFilter.filters.length > 0) {
      var stateIndex = this.gridFilter.filters.findIndex((x: any) => x.field == "masterWorkersCompensationTier")
      if (stateIndex > -1)
        this.gridFilter.filters.splice(stateIndex, 1)
      let stateFilter = {
        field: "masterWorkersCompensationTier",
        operator: "contains",
        value: event.toString()
      }
      if (event.length > 0) this.gridFilter.filters.push(stateFilter);
    } else {
      let stateFilter = {
        field: "masterWorkersCompensationTier",
        operator: "contains",
        value: event.toString()
      }
      this.gridFilter.filters.push(stateFilter)
    }
    this.pageNo = 1;
    this.skip = 0;
    this.totalCount = 0;
    this.getCarriersListInfo();
  }

  public onmasterCarrierTypeChange(event: any): void {
    if (this.gridFilter.filters == undefined) this.gridFilter = { logic: 'and', filters: [] };
    if (this.gridFilter.filters.length > 0) {
      var stateIndex = this.gridFilter.filters.findIndex((x: any) => x.field == "masterCarrierType")
      if (stateIndex > -1)
        this.gridFilter.filters.splice(stateIndex, 1)
      let stateFilter = {
        field: "masterCarrierType",
        operator: "contains",
        value: event.toString()
      }
      if (event.length > 0) this.gridFilter.filters.push(stateFilter);
    } else {
      let stateFilter = {
        field: "masterCarrierType",
        operator: "contains",
        value: event.toString()
      }
      this.gridFilter.filters.push(stateFilter)
    }
    this.pageNo = 1;
    this.skip = 0;
    this.totalCount = 0;
    this.getCarriersListInfo();
  }
}
